import { useEffect } from 'react';

const NAMS_WEB_APP_V2_CAPTCHA_SITE_KEY =
  '6Lc8xgkqAAAAAGTl0IjxlA5gHgGHDmOe1864AtUv';

export const useLoadCaptchScript = () => {
  useEffect(() => {
    window.nams_captchaLoaded = false;

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
    script.async = true;
    script.onload = () => {
      window.nams_captchaLoaded = true;
      console.info('Captcha loaded');
    };
    script.onerror = () => {
      console.error('Captcha loaded failed');
    };
    document.body.appendChild(script);

    return () => {
      // Clean up if necessary
      try {
        document.body.removeChild(script);
      } catch (error) {
      } finally {
        window.nams_captchaLoaded = false;
      }
    };
  }, []);
};

export const captchaThings = {
  isReady: () => {
    return (
      // @ts-ignore
      typeof grecaptcha !== 'undefined' &&
      // @ts-ignore
      typeof grecaptcha.render === 'function'
    );
  },
  reset: () => {
    try {
      // @ts-ignore No types available from google
      grecaptcha.reset();
    } catch (error) {}
  },

  getResponse: async () => {
    try {
      // @ts-ignore No types available from google
      const token = grecaptcha.getResponse() as string;

      if (!token?.length) {
        return {
          ok: false,
        } as const;
      }

      return {
        ok: true,
        token,
      } as const;
    } catch (error) {
      return {
        ok: false,
      } as const;
    }
  },

  renderCaptcha: (element: any) => {
    try {
      // @ts-ignore No types available from google
      grecaptcha.render(element, {
        sitekey: NAMS_WEB_APP_V2_CAPTCHA_SITE_KEY,
      });
    } catch (error) {
      if (
        error instanceof Error &&
        error.message === 'reCAPTCHA has already been rendered in this element'
      ) {
        return;
      }
      console.error('Captch render error', error);
    }
  },
};
