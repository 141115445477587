import { APIResponseErrorNotice } from '~/feature-components/APIResponseErrorNotice';
import {
  Button,
  Link as ChakraLink,
  Container,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { Field } from '~/design-system/fields/Field';
import { Link, useFetcher } from '@remix-run/react';
import { Text } from '~/design-system/text';
import { authenticate } from '~/public-api/authentication/authenticate.server';
import { createSession, redirectIfLoggedIn } from '~/utils/session.server';

import { captchaThings, useLoadCaptchScript } from './captcha';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInterval } from '~/utils/useInterval';
import { useSubmitFetcherOnError } from '~/utils/useSubmitFetcherOnFail';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { zx } from 'zodix';
import type { ActionFunctionArgs, LoaderFunction } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';

const routeQuerySchema = z.object({
  redirectTo: z.optional(z.string()),
});

export const loader: LoaderFunction = async (loader) => {
  const query = zx.parseQuery(loader.request, routeQuerySchema);

  await redirectIfLoggedIn(
    loader.request,
    query.redirectTo?.length ? query.redirectTo : undefined
  );
  return null;
};

const loginFormSchema = z.object({
  username: z.string().min(1, { message: 'Username is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
  captchaToken: z.string().min(1).optional(),
});

export const action = async (action: ActionFunctionArgs) => {
  await redirectIfLoggedIn(action.request);

  const form = loginFormSchema.parse(await action.request.json());

  const captchaToken = form.captchaToken;

  if (!captchaToken?.length) {
    return {
      ok: false,
      statusCode: 400,
      statusText: 'Invalid captcha token',
    } as const;
  }

  const authenticationReponse = await authenticate(
    { ...form, captchaToken: captchaToken },
    action.request
  );
  if (authenticationReponse.ok) {
    return createSession(authenticationReponse.data, action.request);
  }
  return authenticationReponse;
};

type LoginFormValue = z.infer<typeof loginFormSchema>;

export default function Login() {
  const [captchaReady, setCaptchaReady] = useState(false);
  const captchContainerRef = useRef<any>();
  const { formState, handleSubmit, register } = useForm<LoginFormValue>({
    resolver: zodResolver(loginFormSchema),
  });

  useEffect(() => {
    /**
     * Sometimes the user can land on the login page by hitting the back button. In this case it's a client navigation which
     *  doesn't trigger the server redirect from the loader so we always hit the loader on mount so that it gets hits every time.
     *
     * If the user is not logged in, this does nothing.
     * If the user arrived through a fresh page load, the server redirect will kick in before this page mounts.
     * If the user arrived through back button, this will make sure the loader gets hit and the redirect kicks in if logged in.
     */
    // @ts-ignore It works by just loading the current url.
    submitFetcher.load(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFetcher = useFetcher<any>();

  useLoadCaptchScript();

  useSubmitFetcherOnError({
    fetcherData: submitFetcher.data,
    fetcherState: submitFetcher.state,
    onError: () => {
      captchaThings.reset();
    },
  });

  useInterval(() => {
    if (
      !captchaReady &&
      window.nams_captchaLoaded === true &&
      captchaThings.isReady()
    ) {
      captchaThings.renderCaptcha(captchContainerRef.current);
      setCaptchaReady(true);
    }
  }, 300);

  useEffect(() => {
    return () => {
      captchaThings.reset();
    };
  }, []);

  return (
    <Container height='full' centerContent justifyContent='center'>
      <Container
        width={{ base: '100%', lg: 'md' }}
        paddingX={{ base: '6', lg: '12' }}
        paddingY='12'
        background='white'
        borderRadius='lg'
      >
        <Stack gap='2' alignItems='center'>
          <img
            src='/header-logo.svg'
            alt='header logo'
            width={72}
            height={72}
          />
          <Text color='gray.500' fontSize='md' align='center' paddingBottom='8'>
            Navace Asset Management System
          </Text>
        </Stack>
        <form
          onSubmit={handleSubmit(async (values) => {
            const captchaResponse = await captchaThings.getResponse();
            if (captchaResponse.ok) {
              // console.log('captchaResponse', captchaResponse);
              submitFetcher.submit(
                {
                  ...values,
                  captchaToken: captchaResponse.token,
                },
                {
                  method: 'POST',
                  encType: 'application/json',
                }
              );
            }
          })}
        >
          <Stack gap='4' suppressHydrationWarning>
            <Heading size='md' textAlign='center'>
              Sign in
            </Heading>
            <APIResponseErrorNotice
              fetcherData={submitFetcher.data}
              title='Sign in failed'
            />
            <Field label='Username' error={formState.errors.username?.message}>
              <Input type='text' {...register('username')} />
            </Field>
            <Field label='Password' error={formState.errors.password?.message}>
              <Input type='password' {...register('password')} />
            </Field>
            <ChakraLink as={Link} to='/reset-password' color='blue.500'>
              <Text fontSize='sm'>Forgot Password?</Text>
            </ChakraLink>
            <div id='captcha-widget' ref={captchContainerRef} />
            <Button
              colorScheme='blue'
              type='submit'
              isLoading={submitFetcher.state !== 'idle'}
              isDisabled={!captchaReady}
            >
              Sign in
            </Button>
          </Stack>
        </form>
      </Container>
    </Container>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: 'Navace - Login' }];
};
